<template>
  <div class="map-container">
    <div id="map"></div>
    
    <!-- Track Details Overlay -->
    <div class="track-details-container" :class="{ 'is-collapsed': !showTrackDetails }">
      <button class="toggle-button" @click="toggleTrackDetails">
        {{ showTrackDetails ? '›' : '‹' }}
      </button>
      <div class="track-details" v-if="selectedTrackDetails">
        <h3>Track Details</h3>
        <p>Name: {{ selectedTrackDetails.name }}</p>
        <p>Length: {{ selectedTrackDetails.length }} km</p>
        <p>Description: {{ selectedTrackDetails.description }}</p>
        <p>Start Time: {{ formatTime(selectedTrackDetails.start_time) }}</p>
        <p>End Time: {{ formatTime(selectedTrackDetails.end_time) }}</p>
        
        <!-- Track Statistics Link -->
        <div class="stats-section">
          <router-link 
            v-if="selectedTrack" 
            :to="`/statistics/${selectedTrack}`" 
            class="stats-link"
          >
            <div class="stats-link-content">
              <span class="stats-icon">
                <i class="fas fa-chart-line"></i>
              </span>
              <div class="stats-text">
                <span class="stats-title">View Statistics</span>
                <span class="stats-subtitle">Speed, elevation, and more</span>
              </div>
              <span class="arrow-icon">
                <i class="fas fa-arrow-right"></i>
              </span>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <!-- Track List Sidebar -->
    <div class="controls">
      <div class="track-list">
        <h3>GPS Tracks</h3>
        <ul>
          <li v-for="track in tracks" 
              :key="track.track_id"
              :class="{ active: selectedTrack === track.track_id }"
              @click="selectTrack(track)">
            {{ track.name || `Track ${track.track_id}` }}
            <span class="track-length">{{ track.length }} km</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import gpsData from '../assets/gps_data.json';

export default {
  name: 'HelloWorld',
  data() {
    return {
      map: null,
      tracks: [],
      selectedTrack: null,
      selectedTrackDetails: null,
      currentPolyline: null,
      showTrackDetails: true
    }
  },
  mounted() {
    this.initializeMap();
    this.loadTracks();
    this.initializeSelectedTrack();
    // Check for stored track ID
    const storedTrackId = localStorage.getItem('selectedTrackId');
    if (storedTrackId) {
      // Find and select the track
      const track = this.tracks.find(t => t.track_id.toString() === storedTrackId);
      if (track) {
        this.selectTrack(track);
      }
      // Clear the stored ID
      localStorage.removeItem('selectedTrackId');
    }
  },
  methods: {
    initializeMap() {
      this.map = L.map('map').setView([35.9132, -79.0558], 13);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors'
      }).addTo(this.map);
    },
    loadTracks() {
      this.tracks = gpsData.tracks;
    },
    selectTrack(track) {
      this.$router.push(`/track/${track.track_id}`);
      
      this.selectedTrack = track.track_id;
      this.selectedTrackDetails = track;
      
      if (this.currentPolyline) {
        this.map.removeLayer(this.currentPolyline);
      }

      const coordinates = [];
      track.segments.forEach(segment => {
        segment.points.forEach(point => {
          if (point.latitude && point.longitude) {
            coordinates.push([point.latitude, point.longitude]);
          }
        });
      });

      this.currentPolyline = L.polyline(coordinates, {
        color: 'blue',
        weight: 3
      }).addTo(this.map);

      this.map.fitBounds(this.currentPolyline.getBounds());
    },
    formatTime(timestamp) {
      if (!timestamp) return 'N/A';
      return new Date(timestamp).toLocaleString();
    },
    toggleTrackDetails() {
      this.showTrackDetails = !this.showTrackDetails;
    },
    initializeSelectedTrack() {
      const trackId = this.$route.params.trackId;
      
      if (trackId) {
        const track = this.tracks.find(t => t.track_id.toString() === trackId);
        if (track) {
          this.selectTrack(track);
          return;
        }
      }
      
      if (this.tracks.length > 0) {
        this.selectTrack(this.tracks[0]);
      }
    }
  }
}
</script>

<style scoped>
.map-container {
  position: relative;
  display: flex;
  height: 100vh;
}

#map {
  flex: 1;
  height: 100%;
  z-index: 1;
}

.controls {
  width: 300px;
  padding: 20px;
  background: #f5f5f5;
  overflow-y: auto;
  z-index: 1000;
}

.track-list ul {
  list-style: none;
  padding: 0;
}

.track-list li {
  padding: 10px;
  margin: 5px 0;
  background: white;
  border-radius: 4px;
  cursor: pointer;
}

.track-list li:hover {
  background: #eee;
}

.track-list li.active {
  background: #e3f2fd;
}

.track-length {
  float: right;
  color: #666;
}

.track-details-container {
  position: absolute;
  top: 20px;
  right: 320px; /* Adjust based on your controls width */
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  z-index: 1000;
}

.track-details-container.is-collapsed {
  transform: translateX(calc(100% - 30px));
}

.toggle-button {
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 60px;
  background: white;
  border: none;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.1);
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-button:hover {
  background: #f5f5f5;
}

.track-details {
  padding: 15px;
  min-width: 250px;
}

h3 {
  margin-top: 0;
  color: #333;
}

.stats-section {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #eee;
}

.stats-link {
  text-decoration: none;
  display: block;
}

.stats-link-content {
  display: flex;
  align-items: center;
  background: #f8f9fa;
  padding: 12px 15px;
  border-radius: 8px;
  gap: 12px;
  transition: background-color 0.2s ease;
}

.stats-link:hover .stats-link-content {
  background: #f0f2f5;
}

.stats-icon {
  font-size: 20px;
  color: #2196F3;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stats-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.stats-title {
  color: #2196F3;
  font-weight: 600;
  font-size: 15px;
  white-space: nowrap;
}

.stats-subtitle {
  color: #666;
  font-size: 13px;
  margin-top: 2px;
  white-space: nowrap;
}

.arrow-icon {
  color: #2196F3;
  font-size: 16px;
  opacity: 0.7;
  transition: opacity 0.2s;
  margin-left: auto;
  padding-left: 8px;
}

.stats-link:hover .arrow-icon {
  opacity: 1;
  transform: translateX(2px);
  transition: all 0.2s ease;
}

/* Remove unused styles */
.nav-links {
  display: none;
}
</style>
