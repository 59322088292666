<template>
  <div class="statistics-container">
    <div class="stats-nav">
      <button 
        :class="{ active: !selectedTrackId }" 
        @click="goToOverallStats"
      >
        Overall Statistics
      </button>
      <button 
        :class="{ active: selectedTrackId }" 
        :disabled="!selectedTrackId"
        @click="goToTrackStats"
      >
        Track Statistics
      </button>
    </div>

    <!-- Overall Statistics -->
    <div v-if="!selectedTrackId" class="overall-stats">
      <h2>Overall Statistics</h2>
      
      <!-- Summary Cards -->
      <div class="summary-cards">
        <div class="stat-card">
          <h4>Total Distance</h4>
          <p>{{ totalDistance.toFixed(2) }} km</p>
        </div>
        <div class="stat-card">
          <h4>Total Tracks</h4>
          <p>{{ tracks.length }}</p>
        </div>
        <div class="stat-card">
          <h4>Avg Trip Length</h4>
          <p>{{ (totalDistance / tracks.length).toFixed(2) }} km</p>
        </div>
      </div>

      <!-- Charts Grid -->
      <div class="charts-grid">
        <div class="chart-container">
          <h3>Distance per Year</h3>
          <div id="yearly-distance-chart" class="chart"></div>
        </div>
        
        <div class="chart-container">
          <h3>Speed Distribution</h3>
          <div id="speed-distribution-chart" class="chart"></div>
        </div>

        <div class="chart-container">
          <h3>Trip Length Distribution</h3>
          <div id="length-distribution-chart" class="chart"></div>
        </div>

        <div class="chart-container">
          <h3>Time of Day Distribution</h3>
          <div id="time-distribution-chart" class="chart"></div>
        </div>
      </div>
    </div>

    <!-- Track-specific Statistics -->
    <div v-if="selectedTrackId" class="track-stats">
      <div class="track-header">
        <h2>Track Statistics</h2>
        <router-link 
          :to="`/`" 
          class="view-map-link"
          @click="setTrackInMap"
        >
          <i class="fas fa-map-marked-alt"></i>
          View on Map
        </router-link>
      </div>
      
      <!-- Track Summary -->
      <div class="summary-cards">
        <div class="stat-card">
          <h4>
            <i class="fas fa-tachometer-alt"></i>
            Average Speed
          </h4>
          <p>{{ averageSpeed.toFixed(2) }} km/h</p>
        </div>
        <div class="stat-card">
          <h4>
            <i class="fas fa-mountain"></i>
            Max Elevation
          </h4>
          <p>{{ maxElevation.toFixed(0) }} m</p>
        </div>
        <div class="stat-card">
          <h4>
            <i class="fas fa-arrow-up"></i>
            Total Ascent
          </h4>
          <p>{{ totalAscent.toFixed(0) }} m</p>
        </div>
      </div>

      <!-- Elevation Profile -->
      <div class="chart-container">
        <h3>Elevation Profile</h3>
        <div id="elevation-chart" class="chart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import gpsData from '../assets/gps_data.json';

export default {
  name: 'StatisticsView',
  data() {
    return {
      tracks: [],
      totalDistance: 0,
      averageSpeed: 0,
      maxElevation: 0,
      totalAscent: 0,
      isLoading: true
    }
  },
  computed: {
    selectedTrackId() {
      return this.$route.params.trackId;
    }
  },
  watch: {
    selectedTrackId: {
      immediate: true,
      handler(newId) {
        if (this.isLoading) return;
        
        if (newId) {
          this.renderTrackStats();
        } else {
          this.renderOverallStats();
        }
      }
    }
  },
  created() {
    this.tracks = gpsData.tracks;
    this.isLoading = false;
    
    if (this.selectedTrackId) {
      this.renderTrackStats();
    } else {
      this.renderOverallStats();
    }
  },
  methods: {
    goToOverallStats() {
      if (this.$route.path !== '/statistics') {
        this.$router.push('/statistics');
      }
    },
    goToTrackStats() {
      const trackId = parseInt(this.selectedTrackId);
      if (trackId && this.$route.path !== `/statistics/${trackId}`) {
        this.$router.push(`/statistics/${trackId}`);
      }
    },
    renderChart(selector, renderFunction) {
      this.$nextTick(() => {
        const container = document.querySelector(selector);
        if (container) {
          const containerWidth = container.clientWidth;
          const containerHeight = container.clientHeight;
          renderFunction(containerWidth, containerHeight);
        }
      });
    },

    renderOverallStats() {
      this.calculateTotalDistance();
      
      // Render each chart with proper dimensions
      this.renderChart('#yearly-distance-chart', this.renderYearlyDistanceChart);
      this.renderChart('#speed-distribution-chart', this.renderSpeedDistribution);
      this.renderChart('#length-distribution-chart', this.renderLengthDistribution);
      this.renderChart('#time-distribution-chart', this.renderTimeDistribution);
    },

    renderYearlyDistanceChart(containerWidth, containerHeight) {
      const margin = {top: 20, right: 30, bottom: 30, left: 40};
      const width = containerWidth - margin.left - margin.right;
      const height = containerHeight - margin.top - margin.bottom;

      // Create monthly data
      const monthlyData = d3.group(this.tracks, d => {
        const date = new Date(d.start_time);
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
      });

      const data = Array.from(monthlyData, ([yearMonth, tracks]) => ({
        date: new Date(`${yearMonth}-01`), // Convert to Date object for easier handling
        distance: d3.sum(tracks, d => d.length)
      })).sort((a, b) => a.date - b.date); // Sort by date

      // Clear previous chart
      d3.select("#yearly-distance-chart").selectAll("*").remove();

      const svg = d3.select("#yearly-distance-chart")
        .append("svg")
        .attr("width", containerWidth)
        .attr("height", containerHeight)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Create scales
      const x = d3.scaleTime()
        .range([0, width])
        .domain(d3.extent(data, d => d.date));

      const y = d3.scaleLinear()
        .range([height, 0])
        .domain([0, d3.max(data, d => d.distance)]);

      // Create line generator
      const line = d3.line()
        .x(d => x(d.date))
        .y(d => y(d.distance))
        .curve(d3.curveMonotoneX); // Add smooth curve

      // Add the line path
      svg.append("path")
        .datum(data)
        .attr("fill", "none")
        .attr("stroke", "#4CAF50")
        .attr("stroke-width", 2)
        .attr("d", line);

      // Add dots
      svg.selectAll(".dot")
        .data(data)
        .enter()
        .append("circle")
        .attr("class", "dot")
        .attr("cx", d => x(d.date))
        .attr("cy", d => y(d.distance))
        .attr("r", 4)
        .attr("fill", "#4CAF50")
        .attr("stroke", "white")
        .attr("stroke-width", 2);

      // Add axes
      svg.append("g")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(x)
          .ticks(d3.timeMonth.every(3)) // Show every 3 months
          .tickFormat(d3.timeFormat("%Y-%m"))); // Format as YYYY-MM

      svg.append("g")
        .call(d3.axisLeft(y));
    },

    renderSpeedDistribution(containerWidth, containerHeight) {
      const margin = {top: 20, right: 30, bottom: 30, left: 40};
      const width = containerWidth - margin.left - margin.right;
      const height = containerHeight - margin.top - margin.bottom;

      // Calculate speeds
      const speeds = this.tracks.map(track => {
        const duration = (new Date(track.end_time) - new Date(track.start_time)) / (1000 * 60 * 60);
        return track.length / duration;
      }).filter(speed => speed >= 0 && speed <= 150); // Filter out unrealistic speeds

      // Calculate statistics for box plot
      const sorted = speeds.sort((a, b) => a - b);
      const q1 = d3.quantile(sorted, 0.25);
      const median = d3.quantile(sorted, 0.5);
      const q3 = d3.quantile(sorted, 0.75);
      const iqr = q3 - q1;
      const whiskerBottom = Math.max(0, q1 - 1.5 * iqr);
      const whiskerTop = Math.min(d3.max(speeds), q3 + 1.5 * iqr);

      // Clear previous chart
      d3.select("#speed-distribution-chart").selectAll("*").remove();

      const svg = d3.select("#speed-distribution-chart")
        .append("svg")
        .attr("width", containerWidth)
        .attr("height", containerHeight)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Create scale
      const y = d3.scaleLinear()
        .domain([0, d3.max(speeds)])
        .range([height, 0]);

      // Draw box
      const boxWidth = 50;
      const center = width / 2;

      // Draw vertical line (whiskers)
      svg.append("line")
        .attr("x1", center)
        .attr("x2", center)
        .attr("y1", y(whiskerBottom))
        .attr("y2", y(whiskerTop))
        .attr("stroke", "#2196F3")
        .attr("stroke-width", 1);

      // Draw box
      svg.append("rect")
        .attr("x", center - boxWidth/2)
        .attr("y", y(q3))
        .attr("width", boxWidth)
        .attr("height", y(q1) - y(q3))
        .attr("fill", "#2196F3")
        .attr("opacity", 0.7);

      // Draw median line
      svg.append("line")
        .attr("x1", center - boxWidth/2)
        .attr("x2", center + boxWidth/2)
        .attr("y1", y(median))
        .attr("y2", y(median))
        .attr("stroke", "white")
        .attr("stroke-width", 2);

      // Add dots for outliers
      const outliers = speeds.filter(d => d < whiskerBottom || d > whiskerTop);
      svg.selectAll("circle")
        .data(outliers)
        .enter()
        .append("circle")
        .attr("cx", center)
        .attr("cy", d => y(d))
        .attr("r", 3)
        .attr("fill", "#2196F3")
        .attr("opacity", 0.5);

      // Add y-axis
      svg.append("g")
        .call(d3.axisLeft(y));

      // Add labels
      svg.append("text")
        .attr("x", center)
        .attr("y", height + margin.bottom)
        .attr("text-anchor", "middle")
        .text("Speed (km/h)");
    },

    renderLengthDistribution(containerWidth, containerHeight) {
      const margin = {top: 20, right: 30, bottom: 30, left: 40};
      const width = containerWidth - margin.left - margin.right;
      const height = containerHeight - margin.top - margin.bottom;

      const lengths = this.tracks.map(track => track.length);

      // Clear previous chart
      d3.select("#length-distribution-chart").selectAll("*").remove();

      const svg = d3.select("#length-distribution-chart")
        .append("svg")
        .attr("width", containerWidth)
        .attr("height", containerHeight)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Create histogram
      const histogram = d3.histogram()
        .domain([0, d3.max(lengths)])
        .thresholds(10);

      const bins = histogram(lengths);

      // Create scales
      const x = d3.scaleLinear()
        .domain([0, d3.max(lengths)])
        .range([0, width]);

      const y = d3.scaleLinear()
        .domain([0, d3.max(bins, d => d.length)])
        .range([height, 0]);

      // Add bars
      svg.selectAll("rect")
        .data(bins)
        .enter()
        .append("rect")
        .attr("x", d => x(d.x0))
        .attr("width", d => x(d.x1) - x(d.x0))
        .attr("y", d => y(d.length))
        .attr("height", d => height - y(d.length))
        .attr("fill", "#FF9800");

      // Add axes
      svg.append("g")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(x));

      svg.append("g")
        .call(d3.axisLeft(y));
    },

    renderTimeDistribution(containerWidth, containerHeight) {
      const margin = {top: 20, right: 30, bottom: 30, left: 30};
      const width = Math.min(containerWidth, containerHeight) - margin.left - margin.right;
      const height = width; // Make it square
      const radius = width / 2;

      // Get hours data
      const hours = this.tracks.map(track => new Date(track.start_time).getHours());
      
      // Create histogram data
      const bins = d3.histogram()
        .domain([0, 24])
        .thresholds(24)(hours);

      // Clear previous chart
      d3.select("#time-distribution-chart").selectAll("*").remove();

      const svg = d3.select("#time-distribution-chart")
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", `translate(${width/2 + margin.left},${height/2 + margin.top})`);

      // Create scales
      const angle = d3.scaleLinear()
        .domain([0, 24])
        .range([0, 2 * Math.PI]);

      const r = d3.scaleLinear()
        .domain([0, d3.max(bins, d => d.length)])
        .range([0, radius]);

      // Create arc generator
      const arc = d3.arc()
        .innerRadius(0)
        .outerRadius(d => r(d.length))
        .startAngle(d => angle(d.x0))
        .endAngle(d => angle(d.x1));

      // Add arcs
      svg.selectAll("path")
        .data(bins)
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", "#9C27B0")
        .attr("opacity", 0.7);

      // Add hour labels
      const hours24 = d3.range(0, 24, 3);
      svg.selectAll(".hour-label")
        .data(hours24)
        .enter()
        .append("text")
        .attr("class", "hour-label")
        .attr("x", d => (radius + 20) * Math.sin(angle(d)))
        .attr("y", d => -(radius + 20) * Math.cos(angle(d)))
        .attr("text-anchor", "middle")
        .attr("dy", "0.35em")
        .text(d => d.toString().padStart(2, '0') + ":00");

      // Add circular grid lines
      const gridCircles = [0.25, 0.5, 0.75, 1];
      svg.selectAll(".grid-circle")
        .data(gridCircles)
        .enter()
        .append("circle")
        .attr("class", "grid-circle")
        .attr("r", d => radius * d)
        .attr("fill", "none")
        .attr("stroke", "#ddd")
        .attr("stroke-dasharray", "2,2");
    },

    calculateTotalDistance() {
      this.totalDistance = this.tracks.reduce((sum, track) => sum + track.length, 0);
    },

    renderTrackStats() {
      if (!this.selectedTrackId) return;
      
      // Use track_id instead of id to match HelloWorld.vue
      const track = this.tracks.find(t => t.track_id.toString() === this.selectedTrackId);
      
      if (!track) {
        console.error('Track not found:', this.selectedTrackId);
        console.log('Available tracks:', this.tracks.map(t => t.track_id)); // Debug log
        return;
      }

      console.log('Found track:', track); // Debug log
      this.calculateTrackStats(track);

      this.$nextTick(() => {
        this.renderElevationProfile(track);
      });
    },

    calculateTrackStats(track) {
      // Reset values
      this.totalAscent = 0;
      this.maxElevation = 0;
      this.averageSpeed = 0;

      // Debug logs
      console.log('Track start time:', track.start_time);
      console.log('Track end time:', track.end_time);
      console.log('Track length:', track.length);

      // Calculate time difference in hours
      const startTime = new Date(track.start_time);
      const endTime = new Date(track.end_time);
      const totalTime = (endTime - startTime) / (1000 * 60 * 60); // Convert to hours

      console.log('Total time (hours):', totalTime); // Debug log
      
      // Calculate average speed (km/h)
      if (totalTime > 0) {
        this.averageSpeed = track.length / totalTime;
        console.log('Calculated average speed:', this.averageSpeed); // Debug log
      } else {
        console.warn('Total time is zero or invalid');
      }

      // Calculate elevation stats
      let prevElevation = null;
      track.segments.forEach(segment => {
        segment.points.forEach(point => {
          if (point.elevation) {
            // Update max elevation
            this.maxElevation = Math.max(this.maxElevation, point.elevation);
            
            // Calculate ascent
            if (prevElevation !== null && point.elevation > prevElevation) {
              this.totalAscent += point.elevation - prevElevation;
            }
            prevElevation = point.elevation;
          }
        });
      });

      console.log('Track Stats:', {
        length: track.length,
        totalTime,
        averageSpeed: this.averageSpeed,
        maxElevation: this.maxElevation,
        totalAscent: this.totalAscent
      });
    },

    renderElevationProfile(track) {
      const margin = {top: 20, right: 30, bottom: 30, left: 40};
      const width = 600 - margin.left - margin.right;
      const height = 300 - margin.top - margin.bottom;

      // Prepare elevation data
      const elevationData = [];
      let distance = 0;
      let prevPoint = null;

      track.segments.forEach(segment => {
        segment.points.forEach(point => {
          if (point.elevation) {
            if (prevPoint) {
              const d = this.calculateDistance(
                prevPoint.latitude,
                prevPoint.longitude,
                point.latitude,
                point.longitude
              );
              distance += d;
            }
            elevationData.push({
              distance: distance,
              elevation: point.elevation
            });
            prevPoint = point;
          }
        });
      });

      // Clear previous chart
      d3.select("#elevation-chart").selectAll("*").remove();

      // Create SVG
      const svg = d3.select("#elevation-chart")
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Create scales
      const x = d3.scaleLinear()
        .domain([0, d3.max(elevationData, d => d.distance)])
        .range([0, width]);

      const y = d3.scaleLinear()
        .domain([
          d3.min(elevationData, d => d.elevation) * 0.95,
          d3.max(elevationData, d => d.elevation) * 1.05
        ])
        .range([height, 0]);

      // Create line generator
      const line = d3.line()
        .x(d => x(d.distance))
        .y(d => y(d.elevation))
        .curve(d3.curveBasis);

      // Draw line
      svg.append("path")
        .datum(elevationData)
        .attr("fill", "none")
        .attr("stroke", "#4CAF50")
        .attr("stroke-width", 2)
        .attr("d", line);

      // Add axes
      svg.append("g")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(x))
        .append("text")
        .attr("fill", "#000")
        .attr("x", width)
        .attr("y", -6)
        .text("Distance (km)");

      svg.append("g")
        .call(d3.axisLeft(y))
        .append("text")
        .attr("fill", "#000")
        .attr("transform", "rotate(-90)")
        .attr("y", 6)
        .attr("dy", ".71em")
        .text("Elevation (m)");
    },

    renderSpeedProfile(track) {
      const margin = {top: 20, right: 20, bottom: 30, left: 40};
      const width = 600 - margin.left - margin.right;
      const height = 300 - margin.top - margin.bottom;

      // Prepare speed data with improved calculations
      const speedData = [];
      let distance = 0;

      track.segments.forEach(segment => {
        let prevPoint = null;
        let prevTime = null;

        segment.points.forEach(point => {
          if (prevPoint && prevTime) {
            // Calculate distance between points
            const d = this.calculateDistance(
              prevPoint.latitude,
              prevPoint.longitude,
              point.latitude,
              point.longitude
            );

            // Calculate time difference in hours
            const timeDiff = (new Date(point.time) - prevTime) / (1000 * 60 * 60);

            if (timeDiff > 0) {  // Prevent division by zero
              const speed = d / timeDiff; // km/h
              distance += d;
              
              // Only add points with reasonable speeds (filter out GPS errors)
              if (speed >= 0 && speed <= 150) { // Max reasonable speed 150 km/h
                speedData.push({
                  distance: distance,
                  speed: speed
                });
              }
            }
          }

          prevPoint = point;
          prevTime = new Date(point.time);
        });
      });

      // Only proceed if we have speed data
      if (speedData.length === 0) {
        // Display a message in the chart area
        d3.select("#speed-chart")
          .append("div")
          .attr("class", "no-data-message")
          .style("text-align", "center")
          .style("padding", "20px")
          .style("color", "#666")
          .text("No speed data available for this track");
        return;
      }

      // Clear previous chart
      d3.select("#speed-chart").selectAll("*").remove();

      // Create SVG
      const svg = d3.select("#speed-chart")
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Scales
      const x = d3.scaleLinear()
        .domain([0, d3.max(speedData, d => d.distance)])
        .range([0, width]);

      const y = d3.scaleLinear()
        .domain([0, d3.max(speedData, d => d.speed) * 1.1]) // Add 10% padding to y-axis
        .range([height, 0]);

      // Create line generator with curve smoothing
      const line = d3.line()
        .curve(d3.curveBasis) // Add curve smoothing
        .x(d => x(d.distance))
        .y(d => y(d.speed));

      // Add gradient
      const gradient = svg.append("defs")
        .append("linearGradient")
        .attr("id", "speed-gradient")
        .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", 0)
        .attr("y1", y(0))
        .attr("x2", 0)
        .attr("y2", y(d3.max(speedData, d => d.speed)));

      gradient.append("stop")
        .attr("offset", "0%")
        .attr("stop-color", "#2196F3")
        .attr("stop-opacity", 0.2);

      gradient.append("stop")
        .attr("offset", "100%")
        .attr("stop-color", "#2196F3")
        .attr("stop-opacity", 0.8);

      // Add area under the line
      svg.append("path")
        .datum(speedData)
        .attr("fill", "url(#speed-gradient)")
        .attr("d", d3.area()
          .curve(d3.curveBasis)
          .x(d => x(d.distance))
          .y0(height)
          .y1(d => y(d.speed))
        );

      // Draw line
      svg.append("path")
        .datum(speedData)
        .attr("fill", "none")
        .attr("stroke", "#2196F3")
        .attr("stroke-width", 2)
        .attr("d", line);

      // Add axes
      svg.append("g")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(x).ticks(5))
        .append("text")
        .attr("fill", "#000")
        .attr("x", width)
        .attr("y", -6)
        .text("Distance (km)");

      svg.append("g")
        .call(d3.axisLeft(y))
        .append("text")
        .attr("fill", "#000")
        .attr("transform", "rotate(-90)")
        .attr("y", 6)
        .attr("dy", ".71em")
        .text("Speed (km/h)");
    },

    calculateDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // Earth's radius in km
      const dLat = this.deg2rad(lat2 - lat1);
      const dLon = this.deg2rad(lon2 - lon1);
      const a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      return R * c;
    },

    deg2rad(deg) {
      return deg * (Math.PI/180);
    },

    setTrackInMap() {
      // Store the track ID in localStorage so the map can select it
      localStorage.setItem('selectedTrackId', this.selectedTrackId);
    }
  }
}
</script>

<style scoped>
.statistics-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.stats-nav {
  margin-bottom: 20px;
}

.stats-nav button {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: #f0f0f0;
  transition: background-color 0.2s ease;
}

.stats-nav button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.stats-nav button.active {
  background: #4CAF50;
  color: white;
}

.stats-nav button:not(.active):hover {
  background: #e0e0e0;
}

.charts-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.chart-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  min-height: 400px;
  display: flex;
  flex-direction: column;
}

.chart-container h3 {
  margin: 0 0 15px 0;
  color: #333;
  font-size: 16px;
  font-weight: 600;
}

.chart {
  flex: 1;
  width: 100%;
  min-height: 300px;
  position: relative;
}

/* Chart specific styles */
.dot {
  transition: r 0.2s ease;
}

.dot:hover {
  r: 6;
}

/* Box plot styles */
.box-plot rect {
  transition: opacity 0.2s ease;
}

.box-plot rect:hover {
  opacity: 0.9;
}

/* Time distribution styles */
.hour-label {
  font-size: 12px;
  fill: #666;
}

.grid-circle {
  stroke-opacity: 0.3;
}

/* Histogram styles */
.bar {
  transition: opacity 0.2s ease;
}

.bar:hover {
  opacity: 0.8;
}

/* Summary cards styles */
.summary-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin: 20px 0;
}

.stat-card {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.stat-card h4 {
  margin: 0;
  color: #666;
  font-size: 14px;
  font-weight: 500;
}

.stat-card p {
  margin: 10px 0 0;
  font-size: 24px;
  font-weight: bold;
  color: #4CAF50;
}

/* Axis styles */
.axis-label {
  font-size: 12px;
  fill: #666;
}

.axis path,
.axis line {
  stroke: #ddd;
}

.axis text {
  font-size: 12px;
  fill: #666;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .charts-grid {
    grid-template-columns: 1fr;
  }

  .chart-container {
    min-height: 350px;
  }

  .summary-cards {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

/* Track header styles */
.track-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.track-header h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

/* View on Map link styles */
.view-map-link {
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  background-color: #673AB7;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.view-map-link:hover {
  background-color: #5E35B1;
}

.view-map-link i {
  margin-right: 8px;
}

/* Navigation button styles */
.stats-nav {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
}

.stats-nav button {
  padding: 12px 24px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background: #f5f5f5;
  color: #333;
  transition: all 0.2s ease;
  min-width: 180px;
}

.stats-nav button.active {
  background: #4CAF50;
  color: white;
  box-shadow: 0 2px 4px rgba(76, 175, 80, 0.2);
}

.stats-nav button:not(.active):hover {
  background: #e0e0e0;
}

.stats-nav button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.stat-card h4 i {
  margin-right: 8px;
  color: #4CAF50;
}

.view-map-link i {
  margin-right: 8px;
  font-size: 16px;
}
</style>
