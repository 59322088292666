import { createRouter, createWebHistory } from 'vue-router'
import HelloWorld from '../components/HelloWorld.vue'
import Statistics from '../components/Statistics.vue'

const routes = [
  {
    path: '/',
    component: HelloWorld
  },
  {
    path: '/track/:trackId',
    component: HelloWorld
  },
  {
    path: '/statistics',
    component: Statistics
  },
  {
    path: '/statistics/:trackId',
    component: Statistics
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router 